.header {
  grid-column: 1 / 3;
  grid-row: 1;
  height: auto;
  padding-top: 2%;
  font-family: "Helvetica Neue", "Arial", sans-serif;
  font-weight: 90;
  font-variant-ligatures: normal;
  font-size: 1.6rem;
  letter-spacing: 1px;
}

.header-name {
  padding-left: 0.2%;
  padding-top: 2%;
  font-family: "Helvetica Neue", "Arial", sans-serif;
  font-weight: 60;
  font-variant-ligatures: normal;
  font-size: 1.6rem;
  letter-spacing: 1px;
}

.Header-About {
  width: auto;
  padding-left: 0.2%;
  font-family: "Helvetica Neue", "Arial", sans-serif;
  font-weight: 70;
  font-variant-ligatures: normal;
  font-size: 1.2rem;
  letter-spacing: 1px;
  width: 27ch;
  animation: typing 3s steps(22), blink 1.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
}

.NavItem {
  padding-top: 10px;
  font-family: "Helvetica Neue", "Arial", sans-serif;
  font-weight: 80;
  font-variant-ligatures: normal;
  font-size: 1.2rem;
  display: inline-block;
  letter-spacing: 1px;
}

.NavItem:focus {
  font-weight: 10;
}

.ContactBtns {
  display: inline-block;
}

.column {
  float: left;
  width: auto;
  padding-top: 5px;
  padding-left: 1px;
}

/* Clearfix (clear floats) */
.row::after {
  content: "";
  clear: both;
  display: table;
}

.container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 20px;
  padding-left: 12.5%;
}

.leftHandNav {
  padding-left: 1.5%;
  padding-right: 1.5%;
  border-right: 6px solid black;
  height: 100%;
  position: relative;
  grid-column: 1;
  grid-row: 2;
}

.footer {
  grid-column: 1 / 3;
  grid-row: 8;
  border-radius: 5px;
  padding: 10px;
  font-style: italic;
  font-size: 1rem;
  font-weight: 200;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
